var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gb-ant-select-no-limit-cascader"},[_c('a-cascader',{staticClass:"gb-ant-select-no-limit-item",style:(_vm.cascaderStyle),attrs:{"getPopupContainer":function (triggerNode) { return triggerNode.parentNode; },"notFoundContent":_vm.noDataText,"placeholder":_vm.placeholder,"allowClear":_vm.allowClear,"fieldNames":_vm.cascaderOptionsConfig,"options":_vm.dataList,"value":_vm.value},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"displayRender",fn:function(ref){
var labels = ref.labels;
var selectedOptions = ref.selectedOptions;
return [(_vm.onlyShowLastLabel)?_c('span',{staticClass:"label-last-text"},_vm._l((selectedOptions),function(item,index){return _c('span',{key:index},[(
              index === selectedOptions.length - 1 &&
                selectedOptions[index][_vm.cascaderOptionsConfig.value]
                  .toString()
                  .indexOf(_vm.noLimitCodeTag) === -1
            )?_c('span',{staticClass:"show-text",attrs:{"title":labels[labels.length - 1]}},[_vm._v(" "+_vm._s(_vm._f("textLength")(labels[labels.length - 1],_vm.limitTextLength))+" ")]):(
              index === selectedOptions.length - 1 &&
                selectedOptions[index][_vm.cascaderOptionsConfig.value]
                  .toString()
                  .indexOf(_vm.noLimitCodeTag) !== -1
            )?_c('span',{staticClass:"show-text",attrs:{"title":labels[labels.length - 2]}},[_vm._v(" "+_vm._s(_vm._f("textLength")(labels[labels.length - 2],_vm.limitTextLength))+" ")]):_vm._e()])}),0):_c('span',{staticClass:"label-last-text"},_vm._l((selectedOptions),function(item,index){return _c('span',{key:index},[(
              index === selectedOptions.length - 1 &&
                selectedOptions[index][_vm.cascaderOptionsConfig.value]
                  .toString()
                  .indexOf(_vm.noLimitCodeTag) === -1
            )?_c('span',{staticClass:"show-text",attrs:{"title":labels.join(_vm.labelSplit)}},[_vm._v(" "+_vm._s(labels.join(_vm.labelSplit))+" ")]):(
              index === selectedOptions.length - 1 &&
                selectedOptions[index][_vm.cascaderOptionsConfig.value]
                  .toString()
                  .indexOf(_vm.noLimitCodeTag) !== -1
            )?_c('span',{staticClass:"show-text",attrs:{"title":Array.from(labels)
                .splice(0, labels.length - 1)
                .join(_vm.labelSplit)}},[_vm._v(" "+_vm._s(Array.from(labels) .splice(0, labels.length - 1) .join(_vm.labelSplit))+" ")]):_vm._e()])}),0)]}}])},[_c('a-icon',{staticClass:"icon-down",attrs:{"slot":"suffixIcon","type":"down"},slot:"suffixIcon"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gb-ant-table"},[_c('a-table',_vm._b({attrs:{"pagination":false},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"formatText",fn:function(text, record, index){return [_c('span',{attrs:{"title":text}},[_vm._v(_vm._s(_vm.textEllipsis(text, record, index)))])]}},_vm._l((_vm.$attrs.columns),function(item){return {key:item.scopedSlots &&
        item.scopedSlots.customRender &&
        item.scopedSlots.customRender !== _vm.formatText
          ? item.scopedSlots.customRender
          : '',fn:function(text, record, index){return [_vm._t(item.scopedSlots &&
          item.scopedSlots.customRender &&
          item.scopedSlots.customRender !== _vm.formatText
            ? item.scopedSlots.customRender
            : '',null,null,{ text: text, record: record, index: index })]}}})],null,true)},'a-table',_vm.$attrs,false)),(_vm.paginationData.total > 0 && _vm.showPagination)?_c('gb-ant-pagination',_vm._b({style:(_vm.paginationWrapStyle),attrs:{"showSizeChanger":_vm.paginationShowSizeChanger,"showQuickJumper":_vm.paginationShowQuickJumper},on:{"paginationChange":_vm.handlePaginationChange}},'gb-ant-pagination',_vm.paginationData,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gb-ant-table-tr-form"},[_c('div',{staticClass:"gb-ant-table-tr-form-btn-wrap"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v("新增")])],1),_c('a-table',_vm._b({staticClass:"gb-ant-table-tr-form-wrap",attrs:{"pagination":false},scopedSlots:_vm._u([{key:"formatText",fn:function(text, record, index){return [_c('span',{attrs:{"title":text}},[_vm._v(_vm._s(_vm.textEllipsis(text, record, index)))])]}},{key:"operation",fn:function(text, record, index){return (_vm.useDefaultOperate)?[(record.$isEdit)?_c('a',{staticClass:"link-text",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleOperate('save', { text: text, record: record, index: index })}}},[_vm._v("保存")]):_vm._e(),(record.$isEdit)?_c('a',{staticClass:"link-text",staticStyle:{"margin-left":"10px"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleOperate('cancel', { text: text, record: record, index: index })}}},[_vm._v("取消")]):_vm._e(),(!record.$isEdit)?_c('a',{staticClass:"link-text",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleOperate('edit', { text: text, record: record, index: index })}}},[_vm._v("编辑")]):_vm._e(),(!record.$isEdit)?_c('a',{staticClass:"link-text",staticStyle:{"margin-left":"10px"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleOperate('delete', { text: text, record: record, index: index })}}},[_vm._v("删除")]):_vm._e(),(!record.$isEdit)?_vm._t("operationOther"):_vm._e()]:undefined}},_vm._l((_vm.$attrs.columns),function(item){return {key:item.scopedSlots &&
        item.scopedSlots.customRender &&
        item.scopedSlots.customRender !== _vm.formatText &&
        item.scopedSlots.customRender !== _vm.operation
          ? item.scopedSlots.customRender
          : '',fn:function(text, record, index){return [_vm._t(item.scopedSlots &&
          item.scopedSlots.customRender &&
          item.scopedSlots.customRender !== _vm.formatText
            ? item.scopedSlots.customRender
            : '',null,null,{ text: text, record: record, index: index })]}}})],null,true)},'a-table',_vm.$attrs,false),[_vm._l((_vm.$attrs.columns),function(item){return _c('template',{slot:item.slots && item.slots.title ? item.slots.title : ''},[_vm._t(item.slots && item.slots.title ? item.slots.title : '')],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }